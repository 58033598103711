<template>
  <div class="main">
    <div class="solution">
      <div class="pageBanner">
        <div class="banner">
          <div class="describe">
            <div class="tit">解决方案<span>/SOLUTION</span></div>
            <div class="txt">
              为客户提供专业的数据处理、技术开发、数据解决方案，是一家集研发、生产、销售于一体的<br />大数据企业。
            </div>
          </div>
        </div>
      </div>
      <div class="serveCont">
        <div class="tabs">
          <ul>
            <li
              v-for="(item, k) in tabsList"
              :key="k"
              :class="activeClass == item.id ? 'active' : ''"
              @click="tabsCli(item)"
            >
              <router-link :to="item.url">{{ item.tit }}</router-link>
            </li>
            <!-- <li><a href="">数据应用研发服务中心</a></li>
                        <li><a href="">业务场景综合服务中心</a></li>
                        <li><a href="">智慧云服务中心</a></li> -->
          </ul>
        </div>
        <div class="tabsDtail">
          <div class="tit">
            <div style="font-size: 18px">智慧云服务中心</div>
          </div>
          <div class="cloudCont">
            <div class="basiTit">
              为全国县级政府提供生产管理、客户资源管理等项目开发，实现信息共享、凝聚共识、供需衔接、汇聚资源、全民互动，优化城市管理和服务，改善市民生活质量，打造“智慧县城”。
            </div>
            <!-- 应用区域 -->
            <div class="system">
              <span class="line"></span>
              <div class="yy">应用</div>
              <div class="appTit textIndent">
                智慧县城是数字经济的核心载体，从政府的角度，打动政府的智慧县城的“智慧”在哪里？当大数据、AI、云计算、物联网等New
                ICT技术日趋成熟，作为智慧县城技术催生的土壤，智慧县城开始二次进化。新讯科技提出了“1+1+N”的智慧县城建设思路，即”一个县城数字平台+一个智慧大脑+N个智慧应用”。
              </div>
              <div class="cloudImg0">
                <img src="../assets/cloud2.png" alt="" />
              </div>
              <div class="cloudImg">
                <img src="../assets/cloud1.png" alt="" />
              </div>
            </div>
            <!-- 智慧城市案例区域 -->
            <div class="system">
              <span class="line"></span>
              <div class="yy">案例</div>
              <div class="appTit">
                <span>《智慧城市》</span> <br>
                <span class="textIndent" style="display:inline-block;color:#666">为城市大脑赋能，慧眼识人、多维检索、超级档案建设，灾害检测、安全生产、智慧消防，一网统管配合案件智能处理，配合交通态势研判、交通事件检测，一网通办、智慧化、个性化服务，模块化、实战化、多元化人才培养。</span>
              </div>
              <div class="appTit textIndent">
                公共安全全天候监测预警、实时情报分析；应急管理“零延迟”响应，防患于未然；城市管理“像绣花”一样精细；智慧交通大幅提升交通出行效率；政务服务，群众少跑腿，数据多跑路；智慧教育，产教融合协同育人。让智慧城市更安全、更从容、更通畅、更宜居。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeClass: 4,
      appDataList: [
        {
          id: 0,
          imgs: require("../assets/app1.png"),
          tit: "档案数字化",
          cont: "以国家档案局发布的《纸质档案数字化技术规范》图像处理检着标准为基础,可赧据客户具体需求针对性调整。",
        },
        {
          id: 1,
          imgs: require("../assets/app2.png"),
          tit: "表单数字化",
          cont: "利用新讯的智能表格识别技术，从各类机打或手写表单的单元格中采集数据，并填入到相应数据库字段中。广泛应用于各类申请表、登记表、申报表、调查问卷、票据、快运单等数据采集业务。",
        },
        {
          id: 2,
          imgs: require("../assets/app3.png"),
          tit: "报纸数字化",
          cont: "全流程系统70余道数据处理工序，对纸质或各类电子格式的报纸内容提供数据化服务，最终以检索系统的形式进行成品交付，其中图像为400dpi以上高清无倾斜无污渍内容，文字错误率控制在万分之一以下，内容标引项最多可达40余类，100%状态下双层PDF反显低于l毫米。",
        },
        {
          id: 3,
          imgs: require("../assets/app4.png"),
          tit: "图书数字化",
          cont: "提供标准差异化的图书扫描加工、精度细分化的图书文本制作、格式多样化的电子书转码加工、以及图书文字摘录等服务。内容可设计纸本简体新书、拥有电子数据的简体新书、港澳台及国外繁体新书、繁体旧书、民国图书、杂志、技术报告、学位论文、影印古籍、珍本古籍、特藏图书、年鉴、地方志等。",
        },
      ],
      tabsList: [
        {
          id: 1,
          url: "/solution/basic_data?tab=1",
          tit: "基础数据处理服务中心",
        },
        {
          id: 2,
          url: "/solution/data_apply?tab=2",
          tit: "数据应用研发服务中心",
        },
        {
          id: 3,
          url: "/solution/business_scenes?tab=3",
          tit: "业务场景综合应用中心",
        },
        {
          id: 4,
          url: "/solution/cloud?tab=4",
          tit: "智慧云服务中心\xa0 \xa0 \xa0 \xa0 \xa0\xa0",
        },
      ],
    };
  },
  methods: {
    tabsCli(id) {
      this.activeClass = id.id;
    },
  },
};
</script>
<style lang="scss">
@import "../scss/solution.scss";
</style>